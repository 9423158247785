import { RoutePath } from 'RoutePath';
import { atom, selector } from 'recoil';

// TODO: Create effect to store the state to localStorage | sessionStorge | etc..
export const flowState = atom<RoutePath[]>({
  key: 'flowState',
  default: [RoutePath.Welcome],
});

export const currentPageState = selector({
  key: 'currentPageState',
  get: ({ get }) => {
    const flow = get(flowState);

    return flow[flow.length - 1];
  },
});
