import React, { FC } from 'react';
import styled from 'styled-components';
import RouterPhone from './RouterPhone';
import HighFiveImg from './HighFive';
import RouterTestResults from './RouterTestResults';
import { breakpoints } from '@soluto-private/mx-asurion-ui-react-v3';
import { formatSpeed } from 'utils/NetworkScan';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 149px;
  gap: 42px;
  height: 356px;

  ${breakpoints.xs} {
    height: 320px;
  }

  ${breakpoints.sm} {
    height: 320px;
  }

  ${breakpoints.md} {
    height: 320px;
  }

  ${breakpoints.md2} {
    height: 218px;
  }

  ${breakpoints.lg} {
    height: 218px;
  }
`;

const ContentContainer = styled.div`
  width: 306px;
  height: 210px;
  position: relative;
`;

const LoadingBarContainer = styled.div`
  width: 300px;
  height: 8px;
  position: relative;
`;

const LoadingBarWhole = styled.div`
  height: 8px;
  background: #e6e6eb;
  border-radius: 40px;
`;

const LoadingBarProgress = styled.div`
  position: absolute;
  width: ${(props: { progress: number }) => props.progress}%;
  height: 8px;
  top: 0px;
  background: #000000;
  border-radius: 40px;
  transition: width 2s;
`;

type RouterSpeedTestContainerProps = {
  progress: number;
  downloadSpeed: string;
};

const RouterSpeedTestContainer: FC<RouterSpeedTestContainerProps> = ({
  progress,
  downloadSpeed,
}) => {
  const isSpeedTestDone = progress === 100;
  return (
    <MainContainer>
      <ContentContainer>
        {isSpeedTestDone ? <HighFiveImg /> : <RouterPhone />}
      </ContentContainer>
      <LoadingBarContainer>
        <LoadingBarWhole />
        <LoadingBarProgress progress={progress} />
      </LoadingBarContainer>
      {isSpeedTestDone && (
        <RouterTestResults score={formatSpeed(downloadSpeed)} />
      )}
    </MainContainer>
  );
};

export default RouterSpeedTestContainer;
