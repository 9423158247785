import React, { useEffect } from 'react';
import { useLocation } from 'react-router';

import { trackEvent } from '../utils';
import {
  AnalyticClickTypes,
  AnalyticEvents,
  AnalyticSubmitTypes,
  AnalyticViewTypes,
  WithAnalyticsProps,
} from '../types';

type ComponentType<T> = React.ComponentType<T>;
type PropsType<T> = T & WithAnalyticsProps;

const onTrackClickEvent = <T extends React.HTMLAttributes<P>, P>(
  props: PropsType<T>
) => {
  trackEvent(AnalyticEvents.CLICK, {
    type: AnalyticClickTypes.BUTTON_CLICKED,
    buttonText: Array.isArray(props.children)
      ? props.children.join('')
      : props.children,
    ...props?.analyticsData,
  });
};

export const withClickAnalytics =
  <T extends React.HTMLAttributes<P>, P>(Component: ComponentType<T>) =>
  (props: PropsType<T>): JSX.Element => {
    const handleClick = (e: React.MouseEvent<P>) => {
      if (props.onClick) {
        props.onClick(e);
        onTrackClickEvent(props);
      }
    };
    return <Component {...props} onClick={handleClick} />;
  };

export const withSubmitAnalytics =
  <T extends React.HTMLAttributes<P>, P>(Component: ComponentType<T>) =>
  (props: PropsType<T>): JSX.Element => {
    const handleClick = (e: React.MouseEvent<P>) => {
      if (props.onClick) {
        props.onClick(e);
        onTrackClickEvent(props);
        trackEvent(AnalyticEvents.SUBMIT, {
          type: AnalyticSubmitTypes.OPTIONS_SUBMITTED,
          ...props?.analyticsData,
        });
      }
    };
    return <Component {...props} onClick={handleClick} />;
  };

export const withViewAnalytics =
  <T extends WithAnalyticsProps>(Component: ComponentType<T>) =>
  (props: PropsType<T>): JSX.Element => {
    const location = useLocation();

    useEffect(() => {
      trackEvent(AnalyticEvents.VIEW, {
        type: AnalyticViewTypes.PAGE_LOADED,
        route: location.pathname,
        ...props?.analyticsData,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return <Component {...props} />;
  };
