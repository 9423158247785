import React from 'react';
import {
  Text,
  breakpoints,
  colors,
} from '@soluto-private/mx-asurion-ui-react-v3';
import styled from 'styled-components';

const Container = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  border-radius: 0.5rem;
  background-image: linear-gradient(
    to bottom,
    ${colors.neutralBrightest},
    transparent 90%
  );

  ${breakpoints.md} {
    background-image: unset;
    align-items: center;
    justify-content: center;
    grid-template-columns: 1fr 0.9fr;
    grid-template-rows: 1fr;
    column-gap: 1.2rem;
  }
`;

const TextContent = styled.div`
  display: grid;
  padding: 1.5rem 0.75rem;
  border-radius: 0.5rem;

  ${breakpoints.md} {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    grid-template-columns: 1fr 0.9fr;
    column-gap: 1.2rem;
    padding: 1rem;
    background-image: linear-gradient(
      to right,
      ${colors.neutralBrightest},
      transparent 90%
    );
  }
`;

const Title = styled(Text).attrs({ as: 'h2' })`
  &&& {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
`;

const Subtitle = styled(Text).attrs({ as: 'p' })`
  &&& {
    line-height: 1.3;
    margin-bottom: 0;
  }
`;

const RoomImage = styled.img`
  display: block;
  box-sizing: border-box;
  max-width: 100%;
  height: auto;
  margin: 0 auto;

  ${breakpoints.md} {
    grid-column: 2 / -1;
    grid-row: 1 / -1;
  }
`;

export interface RoomProps {
  title: string;
  subTitle: string;
  image: string;
}

export const Room = ({ title, subTitle, image }: RoomProps) => (
  <Container>
    <TextContent>
      <div>
        <Title size={4} weight={'heavy'}>
          {title}
        </Title>
        <Subtitle size={3}>{subTitle}</Subtitle>
      </div>
    </TextContent>
    <RoomImage src={image} alt="living room" />
  </Container>
);
