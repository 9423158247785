import React, { FC } from 'react';
import styled from 'styled-components';
import {
  breakpoints,
  Text,
  colors,
} from '@soluto-private/mx-asurion-ui-react-v3';
import { AsurionExperts } from './assets';
import {
  ProgressStepper,
  ProgressStepperProps,
} from 'components/ProgressStepper';

const GettingStartedContainer = styled.aside`
  grid-area: sidebar;
  background-color: ${colors.white};
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.white};
  border: 0.0625rem solid ${colors.neutralDeep};
  border-radius: 0.5rem;

  ${breakpoints.md2} {
    background-color: ${colors.neutralBrightest};
    border: none;
    border-radius: 0;
    height: 100%;
  }
`;

const Image = styled.img`
  width: 100%;
  max-width: 25rem;
  margin-top: auto;

  ${breakpoints.md2} {
    max-width: none;
  }
`;

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  padding: 1rem;

  ${breakpoints.md2} {
    text-align: left;
    margin: 6.25rem auto 1.5rem;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
`;

export type GettingStartedProps = ProgressStepperProps;

export const GettingStarted: FC<GettingStartedProps> = ({ steps }) => (
  <GettingStartedContainer data-testid="Aside_Container">
    <Wrapper>
      <HeaderWrapper>
        <Text as="h2" size={5} weight="feather">
          Your expert is standing&#8209;by
        </Text>
        <Text as="h4" size={3}>
          Here&apos;s how to get started:
        </Text>
      </HeaderWrapper>
      <ProgressStepper steps={steps} />
    </Wrapper>
    <Image src={AsurionExperts} alt="Protech Experts" />
  </GettingStartedContainer>
);
