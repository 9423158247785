import React, { useContext } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { SingleSpaContext } from 'single-spa-react';
import { environment } from 'environments/environment';
import App from './app';

export const Root = () => {
  const oneServiceContext = useContext(SingleSpaContext);

  if (environment.production) {
    window.location.replace('https://my.asurion.com');
    return null;
  }

  return (
    <BrowserRouter basename={oneServiceContext.routePrefix.prefix}>
      <App />
    </BrowserRouter>
  );
};

export default Root;
