import { useRecoilState, useResetRecoilState } from 'recoil';
import {
  SpeedTestDelegate,
  WixiLoggerDelegate,
  WixiSnap,
  WixiSession,
  WixiSnapConfig,
  GeoIpDelegate,
  WixiCustomer,
} from '@soluto-private/wixi-web-sdk';
import { networkScanState } from 'states/atoms';
import { formatSpeed } from 'utils/NetworkScan';
import { usePageState } from './usePageState';
import { useEffect } from 'react';

export const useNetworkScan = (wixiSnapConfig?: WixiSnapConfig) => {
  const { page, setNetworkState } = usePageState();
  const { downloadSpeed, uploadSpeed } = page.networkState;

  const [networkScan, setNetworkScan] = useRecoilState(networkScanState);
  const resetNetworkScan = useResetRecoilState(networkScanState);
  const { wixiSnap, scanState, goId } = networkScan;

  useEffect(() => {
    console.log('scan state: ', scanState);
  }, [scanState]);

  return {
    scanState: scanState,
    downloadSpeed,
    uploadSpeed,
    downloadSpeedLabel: downloadSpeed && formatSpeed(downloadSpeed).toString(),
    uploadSpeedLabel: uploadSpeed && formatSpeed(uploadSpeed).toString(),
    startScan: async () => {
      const wixiSnap = new WixiSnap(wixiSnapConfig);
      setNetworkScan((netScan) => ({ ...netScan, wixiSnap }));

      WixiSession.onHealthIdChange = (healthId) => {
        console.log('health id: ', healthId);
        setNetworkScan((netScan) => ({
          ...netScan,
          healthId,
        }));
      };

      WixiSession.onGoIdChange = (goId) => {
        console.log('goId: ', goId);
        setNetworkScan((netScan) => ({
          ...netScan,
          goId,
        }));
      };

      GeoIpDelegate.onIspInfoChange = (ispInfo) => {
        console.log('isp info: ', ispInfo);
      };

      SpeedTestDelegate.onUploadSpeedChange = (speed) => {
        console.log('upload speed: ', speed);
        setNetworkState({ uploadSpeed: speed });
      };

      SpeedTestDelegate.onDownloadSpeedChange = (speed) => {
        console.log('download speed: ', speed);
        setNetworkState({ downloadSpeed: speed });
      };

      SpeedTestDelegate.onStatusChange = (status) => {
        setNetworkScan((netScan) => ({
          ...netScan,
          scanState: status,
        }));
      };

      WixiLoggerDelegate.onLog = (logMessage) => {
        console.log('log: ', logMessage);
      };

      await wixiSnap.snap();
    },
    cancelScan: () => {
      wixiSnap?.cancel();
      resetNetworkScan();
      setNetworkState({
        downloadSpeed: '',
        uploadSpeed: '',
        capabilities: undefined,
      });
    },
    getScan: async () => {
      if (!goId) {
        console.error('Error in getScan, goId not found');
        return;
      }

      try {
        const wixiCustomer = new WixiCustomer();
        const results = await wixiCustomer.getScan({ goId });
        console.log('capabilites: ', results.capabilities);
        setNetworkState({ capabilities: results.capabilities });
      } catch (e) {
        console.log('Error in getScan ', e);
      }
    },
  };
};
