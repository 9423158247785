import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '@soluto-private/mx-asurion-ui-react-v3';
import { Welcome } from 'components/PageHeader/Welcome';
import { HighFive } from 'components/common';
import { useNetworkScan } from 'hooks/useNetworkScan';
import { SpeedResult } from './SpeedResult';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 21rem;
  height: auto;
  margin: 0 auto;
  margin-top: 4.81rem;
`;

const Image = styled.img`
  width: 9rem;
  margin-bottom: 3rem;
`;

const SpeedContainer = styled.div`
  gap: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  & > * {
    margin: 0 auto;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoints.md2} {
    padding: 0 1.5rem;
  }
`;

export const NetworkScanComplete = () => {
  const { downloadSpeedLabel, uploadSpeedLabel } = useNetworkScan();

  return (
    <Wrapper>
      <Welcome
        pageTitle="Test complete &#8212; let's keep going"
        pageSubTitle='Select "Continue" to move forward or "Retest" to run this test again.'
      />
      <Container>
        <Image src={HighFive} />
        <SpeedContainer>
          <SpeedResult
            title="Download speed"
            speedValue={downloadSpeedLabel}
            isLoading={false}
            variant="download"
          />
          <SpeedResult
            title="Upload speed"
            speedValue={uploadSpeedLabel}
            isLoading={false}
            variant="upload"
          />
        </SpeedContainer>
      </Container>
    </Wrapper>
  );
};
