import React, { FC } from 'react';
import styled from 'styled-components';

import {
  CheckboxSelectionCard,
  DynamicGrid,
  Text,
  Button as AsurionButton,
  breakpoints,
} from '@soluto-private/mx-asurion-ui-react-v3';
import {
  withClickAnalytics,
  withSubmitAnalytics,
} from '@streaming-advisor/analytics';
import { updateHeader } from '@soluto-private/mx-app-header';
import {
  onlineActivitiesQuestion,
  OnlineActivityValues,
} from '@streaming-advisor/questions';

import { useCheckIfMobileSize } from 'hooks/useCheckIfMobileSize';
import { RoutePath } from 'RoutePath';
import { usePageState } from 'hooks/usePageState';
import { useFlow } from 'hooks/useFlow';
import { Container } from 'components/Main';
import QuestionTextWrapper from './questionTextWrapper';

const Wrapper = styled.div`
  gap: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${breakpoints.xs} {
    padding: 0px 16px;
    margin-bottom: 48px;
  }

  ${breakpoints.sm} {
    padding: 0px 16px;
    margin-bottom: 48px;
  }

  ${breakpoints.md} {
    padding: 0px 16px;
    margin-bottom: 48px;
  }

  ${breakpoints.md2} {
    padding: 0px 48px;
    margin-top: 46px;
  }

  ${breakpoints.lg} {
    padding: 0px 48px;
    margin-top: 46px;
  }
`;

const CheckboxCard = styled(CheckboxSelectionCard)`
  position: relative;
  overflow: hidden;
  height: 168px;

  > div {
    position: absolute;
    right: 0;
    padding: 0;
  }
`;

const CheckboxInner = styled.div`
  display: flex;
  width: 100%;
  height: 140px;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  gap: 8px;
`;

const CheckboxTitle = styled(Text)`
  text-align: center;
`;

const CustomGrid = styled(DynamicGrid)`
  width: 100%;
`;

const CheckboxImg = styled.img`
  width: 84px;
  height: 84px;
`;

const Button = withClickAnalytics(AsurionButton);
const SubmitButton = withSubmitAnalytics(AsurionButton);

type CustomCheckboxProps = {
  checked: boolean;
  onChange: () => void;
  icon?: string;
  title: string;
  subtitle?: string;
};

const Checkbox: FC<CustomCheckboxProps> = ({
  checked,
  onChange,
  icon,
  title,
  subtitle,
}) => (
  <CheckboxCard checked={checked} onChange={onChange}>
    <CheckboxInner>
      <CheckboxImg alt={title} src={icon} />
      <CheckboxTitle>
        <Text as="p" style={{ padding: '0 10px' }}>
          {title}
        </Text>
        <Text as="p" color="#333F48">
          {subtitle}
        </Text>
      </CheckboxTitle>
    </CheckboxInner>
  </CheckboxCard>
);

const Question1: FC = () => {
  const { goTo } = useFlow();
  const isMobileSize = useCheckIfMobileSize();
  const {
    page: { isRouterTest, questionState },
    setQuestionState,
  } = usePageState();

  const onBack = () => {
    const backLink = isRouterTest
      ? RoutePath.RouterSpeedCompleted
      : RoutePath.RouterSpeedQ1;

    goTo(backLink);
  };

  updateHeader({
    view: 'service-view',
    menu: true,
    onBack,
    subNavConfig: {
      showBackButton: true,
      showPartnerLogo: true,
    },
  });

  const onSetSelected = (key: OnlineActivityValues) => {
    const { q1, q2 } = questionState;

    const isNoneOfThese = key === 'noneOfThese';
    if (q1.includes(key)) {
      const newQ1 = q1.filter((item) => item !== key);
      setQuestionState({
        q2,
        q1: newQ1,
      });
    } else {
      const checkedItems = [...q1, key].filter((key) => key !== 'noneOfThese');
      setQuestionState({
        q2,
        q1: isNoneOfThese ? ['noneOfThese'] : checkedItems,
      });
    }
  };

  const onNext = () => {
    goTo(RoutePath.WifiQ2);
  };

  const buttonRightGroup = (
    <>
      <Button onClick={onNext} variant="outline" color="secondary">
        Skip
      </Button>
      <SubmitButton
        disabled={!questionState.q1.length}
        onClick={onNext}
        color="secondary"
        analyticsData={{
          onlineActivitiesOptions: questionState.q1,
        }}
      >
        Continue
      </SubmitButton>
    </>
  );

  return (
    <Container
      steps={['completed', 'inprogress']}
      footer={{
        onBack,
        buttonRightGroup,
      }}
    >
      <Wrapper>
        <QuestionTextWrapper
          question={1}
          title={onlineActivitiesQuestion.customerQuestion}
          subtitle="Some activities require more effort from your network."
        />
        <CustomGrid autoFit={false} minWidth={isMobileSize ? 8.5 : 12}>
          {onlineActivitiesQuestion.options.map((item) => (
            <Checkbox
              key={item.value}
              checked={questionState.q1.includes(item.value)}
              icon={item.icon}
              title={item.label}
              subtitle={item.subLabel}
              onChange={() => onSetSelected(item.value)}
            />
          ))}
        </CustomGrid>
      </Wrapper>
    </Container>
  );
};

export default Question1;
