import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '@soluto-private/mx-asurion-ui-react-v3';
import { updateHeader } from '@soluto-private/mx-app-header';
import { RoutePath } from 'RoutePath';
import { Container } from 'components/Main';
import { useFlow } from 'hooks/useFlow';
import RouterSpeedTestQuestionPage from './routerSpeedTestQuestionPage';
import { usePageState } from 'hooks/usePageState';
import { withSubmitAnalytics } from '@streaming-advisor/analytics';

const MainContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
`;

const SubmitButton = withSubmitAnalytics(Button);

const RouterSpeedTestQuestion = () => {
  const { goTo } = useFlow();
  const { page, setIsRouterTest } = usePageState();

  const answer = (() => {
    if (page.isRouterTest === null) {
      return undefined;
    }
    return page.isRouterTest ? 'yes' : 'no';
  })();

  const [disableContinue, setDisableContinue] = useState(!answer);

  updateHeader({
    view: 'service-view',
    menu: true,
    onBack: () => goTo(RoutePath.TestComplete),
    subNavConfig: {
      showBackButton: true,
      showPartnerLogo: true,
    },
  });

  const buttonRightGroup = (
    <SubmitButton
      color="secondary"
      onClick={() =>
        goTo(
          page.isRouterTest
            ? RoutePath.RouterSpeedStart
            : RoutePath.OnlineActivitiesQ1
        )
      }
      disabled={disableContinue}
      analyticsData={{
        testRouterSpeed: page.isRouterTest,
      }}
    >
      Continue
    </SubmitButton>
  );

  return (
    <MainContainer>
      <Container
        steps={['inprogress']}
        footer={{
          onBack: () => goTo(RoutePath.TestComplete),
          buttonRightGroup,
        }}
      >
        <RouterSpeedTestQuestionPage
          answer={answer}
          onChange={(value) => {
            setIsRouterTest(value === 'yes');
            setDisableContinue(false);
          }}
        />
      </Container>
    </MainContainer>
  );
};

export default RouterSpeedTestQuestion;
