import { FC } from 'react';
import { Text, colors } from '@soluto-private/mx-asurion-ui-react-v3';
import styled from 'styled-components';
import CircleArrowRightFill from './assets/circle-arrow-right-fill.svg';
import CircleCheck from './assets/circle-check.svg';
import CircleEmpty from './assets/circle-empty.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  width: 100%;
`;

const Item = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;

  h4 {
    flex-grow: 1;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  width: 24px;
`;

const Circle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
`;

const LineWrapper = styled.div`
  display: flex;
  align-items: left;
  min-height: 26px;
  height: 100%;
  width: 100%;
  justify-content: center;
`;

const Line = styled.div`
  height: 100%;
  width: 0.125rem;
  background: ${(props) => props.color};
`;

type Step = 'incomplete' | 'inprogress' | 'completed';

const texts: string[] = [
  'Run the network test',
  'Tell us about your home internet',
  'Receive results',
  'Consult with an expert',
];

const icons: Record<Step, string> = {
  incomplete: CircleEmpty,
  inprogress: CircleArrowRightFill,
  completed: CircleCheck,
};

export type ProgressStepperProps = {
  steps?: [Step?, Step?, Step?, Step?];
};

export const ProgressStepper: FC<ProgressStepperProps> = ({ steps = [] }) => (
  <Wrapper>
    {texts.map((text, i) => {
      const status: Step = steps?.[i] ?? 'incomplete';
      const lineColor =
        status === 'completed' ? colors.black : colors.neutralDeeper;
      return (
        <Item key={i}>
          <IconWrapper>
            <Circle>
              <Img src={icons[status]} />
            </Circle>
            {i < texts.length - 1 && (
              <LineWrapper>
                <Line color={lineColor} />
              </LineWrapper>
            )}
          </IconWrapper>
          <Text as="h4" weight="feather" size={3}>
            {text}
          </Text>
        </Item>
      );
    })}
  </Wrapper>
);
