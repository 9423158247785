import React, { VoidFunctionComponent } from 'react';
import styled from 'styled-components';
import { HighFive } from '../../../components/common';

const HFImage = styled.img`
    width: 100%;
    height: 200px;
`;

const HighFiveImg: VoidFunctionComponent = () => <HFImage src={HighFive} />;

export default HighFiveImg;
