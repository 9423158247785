import React, { useEffect, useState } from 'react';
import { RecoilRoot } from 'recoil';
import styled from 'styled-components';
import {
  AuthenticationState,
  getAccessToken,
  getAuthenticationState,
  redirectToLogin,
} from '@soluto-private/mx-app-authentication';
import { updateHeader } from '@soluto-private/mx-app-header';
import { storage, Key } from '@soluto-private/mx-context';
import {
  AsurionDoodleSpinner,
  GlobalStyles,
} from '@soluto-private/mx-asurion-ui-react-v3';
import { initializeWixiSdk } from './utils/NetworkScan';
import AppRoutes from './routes';

storage.set(Key.Client, 'att'); // not sure the right way to set...

const Spinner = styled(AsurionDoodleSpinner)`
  margin: 1.5rem auto;
`;

export const Root = () => {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    updateHeader({
      view: 'service-view',
      menu: true,
      title: 'Title',
      subNavConfig: {
        title: 'SubNav Title',
        showBackButton: false,
        showPartnerLogo: true,
      },
    });

    const authState = getAuthenticationState();
    const isLoggedIn = authState === AuthenticationState.LoggedIn;
    setLoggedIn(isLoggedIn);
    if (!isLoggedIn) {
      redirectToLogin({ redirectUri: window.location.href });
      return;
    }

    const accessToken = getAccessToken();
    initializeWixiSdk(accessToken || '');
  }, []);

  if (!loggedIn) return <Spinner />;

  return (
    <RecoilRoot>
      <GlobalStyles />
      <AppRoutes />
    </RecoilRoot>
  );
};

export default Root;
