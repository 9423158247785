import { RoutePath } from 'RoutePath';
import { useNavigate } from 'react-router';
import { useRecoilState, useRecoilValue } from 'recoil';
import { currentPageState, flowState } from 'states/atoms/flowState';

const timeline = [
  RoutePath.Welcome,
  RoutePath.NetworkTest,
  RoutePath.TestComplete,
  RoutePath.RouterSpeedQ1,
  RoutePath.RouterSpeedStart,
  RoutePath.RouterSpeedProgress,
  RoutePath.RouterSpeedProgress,
  RoutePath.OnlineActivitiesQ1,
  RoutePath.WifiQ2,
  RoutePath.WifiLoading,
  RoutePath.Results,
];

export const useFlow = () => {
  const navigate = useNavigate();
  const [, setFlow] = useRecoilState(flowState);
  const currentPage = useRecoilValue(currentPageState);

  const goTo = (route: RoutePath) => {
    const index = timeline.findIndex((r) => r === route);
    setFlow(timeline.slice(0, index + 1));
    navigate(route);
  };

  return {
    currentPage,
    goTo,
  };
};
