import { SpeedTestStatus, WixiSnap } from '@soluto-private/wixi-web-sdk';
import { atom } from 'recoil';
import { sessionStorageEffect } from '../effects/sessionStorageEffect';

export interface NetworkScanState {
  wixiSnap: WixiSnap | null;
  goId: string | null;
  healthId: string | null;
  scanState: SpeedTestStatus;
}

export const networkScanState = atom<NetworkScanState>({
  key: 'networkState',
  default: {
    wixiSnap: null,
    goId: null,
    healthId: null,
    scanState: SpeedTestStatus.none,
  },
  effects: [sessionStorageEffect('networkState', ['scanState'])],
});
