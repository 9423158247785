export enum RoutePath {
  Home = '/',
  Welcome = '/welcome',
  NetworkTest = '/networktest',
  TestComplete = '/testcomplete',
  RouterSpeedQ1 = '/routerspeedQ1',
  OnlineActivitiesQ1 = '/onlineactivitiesQ1',
  RouterSpeedStart = '/routerspeedstart',
  RouterSpeedProgress = '/routerspeedprogress',
  RouterSpeedCompleted = '/routerspeedcomplete',
  WifiQ2 = '/wifiQ2',
  WifiLoading = '/wifiloading',
  Results = '/results',
  Profile = '/profile',
}
