// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

import { Environment } from './models/environment.model';

export const environment: Environment = {
  production: false,
  wixiPartnerConfig: {
    partnerSecret: '37sdQnH4fmWyzAN7',
    partnerAppId: 'servicesite-web',
    partnerId: 'att',
    partnerAppSecret: 'kZR6q8DD2bKZ2Tgu',
  },
};
