import React from 'react';
import styled from 'styled-components';
import {
  breakpoints,
  Button,
  ButtonGroup,
} from '@soluto-private/mx-asurion-ui-react-v3';
import {
  AnalyticClickTypes,
  withClickAnalytics,
} from '@streaming-advisor/analytics';

const Wrapper = styled.div``;

const CustomButton = styled(withClickAnalytics(Button))`
  &&& {
    width: 100%;

    ${breakpoints.md} {
      width: auto;
    }
  }
`;

const CustomButtonGroup = styled(ButtonGroup)`
  column-gap: 1.5rem;
  row-gap: 0.5rem;
  align-items: stretch;
`;

export const ResultsOverviewButtons = () => {
  const phoneNumber = {
    label: '800-111-2222',
    value: '+18001112222',
  };

  return (
    <Wrapper>
      <CustomButtonGroup alignment="start">
        <CustomButton
          color="secondary"
          iconSide="left"
          iconSrc="ChatBubbleCircle"
          onClick={() => {
            return;
          }}
          variant="outline"
          analyticsData={{
            type: AnalyticClickTypes.EXPERT_CHAT_CLICKED,
          }}
        >
          Chat with an expert
        </CustomButton>
        <CustomButton
          color="secondary"
          iconSide="left"
          iconSrc="Phone"
          onClick={() => {
            window.open(`tel:${phoneNumber.value}`);
          }}
          analyticsData={{
            type: AnalyticClickTypes.EXPERT_CALL_CLICKED,
          }}
        >
          Call: {phoneNumber.label}
        </CustomButton>
      </CustomButtonGroup>
    </Wrapper>
  );
};
