import React, { VoidFunctionComponent } from 'react';
import { RouterMain, Phone, Line } from 'pages/routerSpeedTest/assets';
import styled from 'styled-components';
import Rings from './Rings';

const RouterContainer = styled.div`
    position: absolute;
    width: 85.13px;
    height: 85.13px;
    left: 61.94px;
    top: 57.99px;
`;

const RouterImage = styled.img`
    position: absolute;
    width: 66.67px;
    height: 61px;
    left: 9.31px;
    top: 11.97px;
`;

const LineImage = styled.img`
    position: absolute;
    width: 100.49px;
    height: 47.29px;
    left: 150.12px;
    top: 132.88px;
`;

const PhoneImage = styled.img`
    position: absolute;
    left: 80.69%;
    right: -0.03%;
    top: 58.02%;
    bottom: 5.9%;
`;

const RouterPhone: VoidFunctionComponent = () => (
    <>
        <Rings />
        <RouterContainer>
            <RouterImage src={RouterMain} />
        </RouterContainer>
        <LineImage src={Line} />
        <PhoneImage src={Phone} />
    </>
);

export default RouterPhone;
