import { AtomEffect } from 'recoil';

export const sessionStorageEffect = <T extends object>(
  sessionKey: string,
  keys?: (keyof T)[]
) =>
  (({ setSelf, onSet }) => {
    const savedValue = sessionStorage.getItem(sessionKey);
    if (savedValue != null) {
      setSelf((param) => ({ ...param, ...JSON.parse(savedValue) }));
    }

    onSet((newValue, _, isReset) => {
      let valueToSave: Partial<T> = {};

      if (keys) {
        keys.forEach((key) => (valueToSave[key] = newValue[key]));
      } else {
        valueToSave = newValue;
      }

      isReset
        ? sessionStorage.removeItem(sessionKey)
        : sessionStorage.setItem(sessionKey, JSON.stringify(valueToSave));
    });
  }) as AtomEffect<T>;
