import { ReactNode } from 'react';

import {
  AuthenticationState,
  getAuthenticationState,
  redirectToLogin,
} from '@soluto-private/mx-app-authentication';

interface Props {
  children: ReactNode;
}

const ProtectedRoute = ({ children }: Props) => {
  const state = getAuthenticationState();

  if (state !== AuthenticationState.LoggedIn) {
    redirectToLogin();
    return null;
  }

  return <>children</>;
};

export default ProtectedRoute;
