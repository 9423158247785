import React, { FC, ReactNode, useCallback, useMemo } from 'react';
import {
  ButtonGroup,
  Button,
  colors,
} from '@soluto-private/mx-asurion-ui-react-v3';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const FooterWrapper = styled.div`
  height: 112px;
  width: 100%;
  border-top: 1px solid ${colors.neutralBrighter};
  background: ${colors.white};
  z-index: 1;
  position: sticky;
  bottom: 0;
`;

const FooterInner = styled.div`
  padding: 16px 48px 48px;
`;

type ButtonContainer = {
  justifyContent: HTMLDivElement['style']['justifyContent'];
};

const ButtonContainer = styled.div<ButtonContainer>`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => props.justifyContent};
  align-items: center;
  padding: 0px;
  gap: 8px;
`;

export type FooterProps = {
  onBack?: boolean | (() => void);
  buttonRightGroup?: ReactNode;
};

const Footer: FC<FooterProps> = ({ onBack, buttonRightGroup }) => {
  const navigate = useNavigate();

  const justifyContent = useMemo<
    HTMLDivElement['style']['justifyContent']
  >(() => {
    if (Boolean(onBack) && Boolean(buttonRightGroup)) {
      return 'space-between';
    } else if (Boolean(onBack) && !buttonRightGroup) {
      return 'left';
    }
    return 'right';
  }, [onBack, buttonRightGroup]);

  const onClickBack = useCallback(() => {
    if (typeof onBack === 'function') {
      onBack();
    } else {
      navigate(-1);
    }
  }, [navigate, onBack]);

  return (
    <FooterWrapper>
      <FooterInner>
        <ButtonContainer justifyContent={justifyContent}>
          {onBack && (
            <Button
              onClick={onClickBack}
              iconSrc="ArrowLeft"
              variant="flat"
              iconSide="left"
              color="secondary"
            >
              Back
            </Button>
          )}
          {buttonRightGroup && <ButtonGroup>{buttonRightGroup}</ButtonGroup>}
        </ButtonContainer>
      </FooterInner>
    </FooterWrapper>
  );
};

export default Footer;
