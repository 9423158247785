import React, { FC } from 'react';
import styled from 'styled-components';
import { Text } from '@soluto-private/mx-asurion-ui-react-v3';

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

type QuestionTextWrapper = {
    question: 1 | 2;
    title: string;
    subtitle: string;
};

const QuestionTextWrapper: FC<QuestionTextWrapper> = ({ question, title, subtitle }) => (
    <TextWrapper>
        <Text size={2} weight='heavy'>
            Question {question}/2
        </Text>
        <Text size={6} weight='feather'>
            {title}
        </Text>
        <Text size={4} weight='feather'>
            {subtitle}
        </Text>
    </TextWrapper>
);

export default QuestionTextWrapper;
