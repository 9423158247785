import {
  Card,
  Icon,
  IconProps,
  Text,
  breakpoints,
  colors,
  fontSizes,
  fontWeights,
} from '@soluto-private/mx-asurion-ui-react-v3';
import { usePageState } from 'hooks/usePageState';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { formatSpeed } from 'utils/NetworkScan';

const CustomCard = styled(Card)`
  border: 2px solid #37e7a7;
  border-radius: 12px;
  max-width: 471px;
  text-align: initial;
  padding: 16px;

  ${breakpoints.xs} {
    width: auto;
  }

  ${breakpoints.smOnly} {
    width: auto;
  }

  ${breakpoints.mdOnly || breakpoints.md2Only} {
    max-width: 471px;
  }
`;

const CustomText = styled(Text)`
  font-weight: 400;
  font-size: ${fontSizes[4]};
  line-height: 1.3;
  display: flex;
  text-align: left;
`;

const SpeedScores = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 12px;
`;

const SpeedScore = styled.div`
  padding: 0px;
  max-height: 4.0625rem;
  width: auto;
  text-align: center;
`;

const SpeedTitle = styled(Text).attrs({ forwardedAs: 'h6' })`
  &&& {
    margin-bottom: 0.62rem;
    font-weight: ${fontWeights.heavy};
    text-align: left;
  }
`;

const Result = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
`;

const IconBadge = styled(Icon)`
  background: ${colors.neutralBrightest};
  padding: 0.19rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ScoreDetail = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #999999;
`;

type ScoreType = {
  icon: IconProps['src'];
  title: string;
  iconTitle: string;
  children: ReactNode;
};

const Score: FC<ScoreType> = ({ title, icon, iconTitle, children }) => (
  <SpeedScore>
    <SpeedTitle>{title}</SpeedTitle>
    <Result>
      <IconBadge aria-hidden="true" size="large" src={icon} title={iconTitle} />
      <Text size={5}>{children}</Text>
      <ScoreDetail>Mbps</ScoreDetail>
    </Result>
  </SpeedScore>
);

export const SpeedResults = () => {
  const {
    page: {
      networkState: { downloadSpeed, uploadSpeed },
      routerState,
    },
  } = usePageState();

  return (
    <CustomCard
      disableSvgColorOverride
      element="div"
      maxWidth={473.13}
      onClick={() => {
        return;
      }}
      type="default"
    >
      <CustomText forwardedAs="h3" size={3}>
        Network speed results
      </CustomText>
      <SpeedScores>
        <Score title="Router speed" icon="Router" iconTitle="router">
          {formatSpeed(routerState?.downloadSpeed || '0') || '-'}
        </Score>
        <Score title="Download speed" icon="ArrowDown" iconTitle="download">
          {formatSpeed(downloadSpeed) || '-'}
        </Score>
        <Score title="Upload speed" icon="ArrowUp" iconTitle="upload">
          {formatSpeed(uploadSpeed) || '-'}
        </Score>
      </SpeedScores>
    </CustomCard>
  );
};
