import React, { VoidFunctionComponent } from 'react';
import { Text } from '@soluto-private/mx-asurion-ui-react-v3';

export interface WelcomeProps {
  pageTitle: string;
  pageSubTitle: string;
}

export const Welcome: VoidFunctionComponent<WelcomeProps> = ({
  pageTitle,
  pageSubTitle,
}) => (
  <div data-testid="LandingPage_LeftHeader">
    <Text size={6} weight="feather" as="h1">
      {pageTitle}
    </Text>
    <br />
    <Text size={4} weight="feather">
      {pageSubTitle}
    </Text>
  </div>
);
