import styled from 'styled-components';
import {
  UnorderedList,
  Text,
  colors,
  AsurionLoadingSpinner,
} from '@soluto-private/mx-asurion-ui-react-v3';
import { usePageState } from 'hooks/usePageState';

const List = styled(UnorderedList)`
  &&& {
    padding-top: 0.67rem;

    ul,
    li:last-child {
      padding: 0;
    }
  }
`;

const Spinner = styled(AsurionLoadingSpinner)`
  margin-top: 0.67rem;
`;

const Dash = styled(Text)`
  display: block;
`;

const capabilitiesMap: Record<string, string> = {
  audioStreaming: 'Audio streaming',
  gaming: 'Gaming',
  uhdVideoStreaming: '4k streaming',
  videoChat: 'Video streaming',
  videoStreaming: 'Streaming on multiple devices',
  webBrowsing: 'Uploading and downloading large files',
};

export const SupportList = () => {
  const { page } = usePageState();
  const { downloadSpeed, capabilities } = page.networkState;

  return (
    <div>
      <Text size={3} weight="heavy">
        Your network should support:
      </Text>
      {capabilities ? (
        <List hasIcons isSpacingCompact>
          {Object.keys(capabilitiesMap).map((capability) => {
            const isSupported =
              typeof capabilities[capability] === 'string' &&
              capabilities[capability] === 'true';

            return (
              <UnorderedList.ListItem
                key={`support-list-${capability}`}
                iconColor={
                  isSupported ? colors.brandGreenDeep : colors.errorDeep
                }
                iconSrc={
                  isSupported ? 'SystemAlertCheckmark' : 'SystemAlertXNo'
                }
              >
                <Text>{capabilitiesMap[capability]}</Text>
              </UnorderedList.ListItem>
            );
          })}
        </List>
      ) : downloadSpeed ? (
        <Spinner />
      ) : (
        <Dash size={5}>-</Dash>
      )}
    </div>
  );
};
