import React, { FC } from 'react';
import { Container } from 'components/Main';
import { ResultsOverview } from 'components/Article';

const ResultsOverviewPage: FC = () => {
  return (
    <Container steps={['completed', 'completed', 'inprogress']}>
      <ResultsOverview />
    </Container>
  );
};

export default ResultsOverviewPage;
