import { updateHeader } from '@soluto-private/mx-app-header';
import {
  AsurionDoodleSpinner,
  Text,
} from '@soluto-private/mx-asurion-ui-react-v3';
import { RoutePath } from 'RoutePath';
import { Container } from 'components/Main';
import styled from 'styled-components';
import { useEffect } from 'react';
import { useFlow } from 'hooks/useFlow';

const Wrapper = styled.div`
  margin: 24px;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10.641px;
`;

const WifiLoading = () => {
  const { goTo } = useFlow();
  const onBack = () => goTo(RoutePath.WifiQ2);

  updateHeader({
    view: 'service-view',
    menu: true,
    onBack,
    subNavConfig: {
      showBackButton: true,
      showPartnerLogo: true,
    },
  });

  useEffect(() => {
    /// TODO: call API here...
    /// remove set timeout and redirect to results once finished.
    setTimeout(() => {
      goTo(RoutePath.Results);
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container
      steps={['completed', 'completed', 'inprogress']}
      footer={{
        onBack,
      }}
    >
      <Wrapper>
        <AsurionDoodleSpinner width="85.126px" />
        <Text size={3} weight="base">
          One moment, tidying up the results...
        </Text>
      </Wrapper>
    </Container>
  );
};

export default WifiLoading;
