import { useEffect, useState } from 'react';

export const useRandomizedProgress = (completed: boolean) => {
  const [progress, setProgress] = useState(0);

  const resetProgress = () => {
    setProgress(0);
  };

  useEffect(() => {
    let timeout: string | number | NodeJS.Timeout | undefined;

    const runInterval = () => {
      const timeoutFunction = () => {
        /// stop adding if reached to 90%
        if (90 <= progress) {
          clearTimeout(timeout);
        } else {
          setProgress(progress + 10);
          runInterval();
        }
      };

      const delay = Math.floor(Math.random() * (3000 - 500)) + 1000;
      timeout = setTimeout(timeoutFunction, delay);
    };

    if (!timeout) {
      runInterval();
    }

    if (completed) {
      clearTimeout(timeout);
      setProgress(100);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [progress, completed]);

  return { progress, resetProgress };
};
