import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button as AsurionButton } from '@soluto-private/mx-asurion-ui-react-v3';
import { updateHeader } from '@soluto-private/mx-app-header';
import { Container } from 'components/Main';
import { NetworkScanComplete } from 'pages/NetworkScan/components';
import { useFlow } from 'hooks/useFlow';
import { RoutePath } from 'RoutePath';
import { useNetworkScan } from 'hooks/useNetworkScan';
import { SpeedTestStatus } from '@soluto-private/wixi-web-sdk';
import { withClickAnalytics } from '@streaming-advisor/analytics';

const MainContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
`;

const Button = withClickAnalytics(AsurionButton);

const TestComplete = () => {
  const { goTo } = useFlow();
  const { cancelScan, scanState } = useNetworkScan();

  useEffect(() => {
    const isResetDone = scanState === SpeedTestStatus.none;
    if (isResetDone) {
      goTo(RoutePath.NetworkTest);
    }
  }, [scanState, goTo]);

  useEffect(() => {
    updateHeader({
      view: 'service-view',
      menu: true,
      onBack: () => goTo(RoutePath.Welcome),
      subNavConfig: {
        showBackButton: true,
        showPartnerLogo: true,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ButtonRightGroup = (
    <>
      <Button variant="outline" color="secondary" onClick={cancelScan}>
        Retest
      </Button>
      <Button color="secondary" onClick={() => goTo(RoutePath.RouterSpeedQ1)}>
        Continue
      </Button>
    </>
  );

  return (
    <MainContainer>
      <Container
        steps={['inprogress']}
        footer={{
          onBack: () => goTo(RoutePath.Welcome),
          buttonRightGroup: ButtonRightGroup,
        }}
      >
        <NetworkScanComplete />
      </Container>
    </MainContainer>
  );
};

export default TestComplete;
