import React, { ReactNode } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import Welcome from './pages/Welcome';
import NetworkScan from './pages/NetworkScan';
import Profile from './pages/profile';
import RouterScan from './pages/routerScan';
import TestComplete from './pages/TestComplete';
import { Question1, Question2 } from './pages/questionnaires';
import ResultsOverviewPage from './pages/ResultsOverview';
import RouterSpeedTestQuestion from './pages/routerSpeedTestQuestion';
import RouterSpeedTestIntroduction from './pages/routerSpeedTestIntroduction';
import WifiLoading from 'pages/WifiLoading';
import { RoutePath } from './RoutePath';
import { withViewAnalytics } from '@streaming-advisor/analytics';

const privateRoute = (child: ReactNode) => (
  <ProtectedRoute>{child}</ProtectedRoute>
);

const PageWrapper = (
  Component: React.ComponentType,
  pageName: string,
  isPrivate: boolean | undefined
) => {
  const WrappedComponent = withViewAnalytics(Component);
  const analyticsData = { pageName };
  return isPrivate ? (
    privateRoute(<WrappedComponent analyticsData={analyticsData} />)
  ) : (
    <WrappedComponent analyticsData={analyticsData} />
  );
};

interface Route {
  pageName: string;
  path: RoutePath;
  isPrivate?: boolean;
  Component: React.ComponentType;
}

export const ROUTES: Route[] = [
  { pageName: 'WelcomePage', path: RoutePath.Welcome, Component: Welcome },
  {
    pageName: 'NetworkTestPage',
    path: RoutePath.NetworkTest,
    Component: NetworkScan,
  },
  {
    pageName: 'TestCompletePage',
    path: RoutePath.TestComplete,
    Component: TestComplete,
  },
  {
    pageName: 'RouterSpeedQ1Page',
    path: RoutePath.RouterSpeedQ1,
    Component: RouterSpeedTestQuestion,
  },
  {
    pageName: 'OnlineActivitiesQ1Page',
    path: RoutePath.OnlineActivitiesQ1,
    Component: Question1,
  },
  {
    pageName: 'RouterSpeedStartPage',
    path: RoutePath.RouterSpeedStart,
    Component: RouterSpeedTestIntroduction,
  },
  {
    pageName: 'RouterSpeedProgressPage',
    path: RoutePath.RouterSpeedProgress,
    Component: RouterScan,
  },
  {
    pageName: 'RouterSpeedCompletedPage',
    path: RoutePath.RouterSpeedCompleted,
    Component: RouterScan,
  },
  { pageName: 'WifiQ2Page', path: RoutePath.WifiQ2, Component: Question2 },
  {
    pageName: 'WifiLoadingPage',
    path: RoutePath.WifiLoading,
    Component: WifiLoading,
  },
  {
    pageName: 'ResultsPage',
    path: RoutePath.Results,
    Component: ResultsOverviewPage,
  },
  {
    pageName: 'ProfilePage',
    path: RoutePath.Profile,
    Component: Profile,
    isPrivate: true,
  },
];

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path={RoutePath.Home}
        element={<Navigate to="/welcome" replace />}
      />
      {ROUTES.map(({ path, pageName, isPrivate, Component }) => (
        <Route
          key={path}
          path={path}
          element={PageWrapper(Component, pageName, isPrivate)}
        />
      ))}
    </Routes>
  );
};

export default AppRoutes;
