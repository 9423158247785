import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  getAuthenticationState,
  logout,
  getUser,
} from '@soluto-private/mx-app-authentication';
import {
  storage,
  Key,
  listen,
  mute,
  storageEvents,
} from '@soluto-private/mx-context';

const Profile = () => {
  const [asurionId, setAsurionId] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [accessToken, setAccessToken] = useState<string>();
  const [services, setServices] = useState<string[]>(storage.get(Key.Services));
  const [partner, setPartner] = useState<string>(storage.get(Key.Client));
  const signOut = useCallback(() => {
    logout({ redirectUri: 'https://www.google.com' });
  }, []);

  const onServiceUpdate = useCallback(() => {
    const services = storage.get(Key.Services);
    setServices(services);
  }, []);

  const onPartnerUpdate = useCallback(() => {
    const client = storage.get(Key.Client);
    setPartner(client);
  }, []);

  useEffect(() => {
    const getUserDetails = async () => {
      const user = await getUser();
      if (!user) return;

      setAsurionId(user.profile.asurion_id);
      setEmail(user.profile.principal_identifier);
      setAccessToken(user.access_token);
    };

    getUserDetails();
    listen(storageEvents.keyUpdated(Key.Services), onServiceUpdate);
    listen(storageEvents.keyUpdated(Key.Client), onPartnerUpdate);

    // It is very important to clear event listner on dispose
    // Remember - our customers might transition between applications!
    return () => {
      mute(storageEvents.keyUpdated(Key.Services), onServiceUpdate);
      mute(storageEvents.keyUpdated(Key.Client), onPartnerUpdate);
    };
  }, [onPartnerUpdate, onServiceUpdate]);

  return (
    <div data-testid="ProfilePage_Container">
      <h2>Profile page</h2>
      <hr />
      <strong>Authentication status:</strong>{' '}
      <span data-testid="ProfilePage_AuthState">
        {getAuthenticationState()}{' '}
      </span>
      <div style={{ textAlign: 'center' }}>
        <button onClick={signOut}>Logout</button>
      </div>
      <hr />
      <strong>Partner:</strong>{' '}
      <span data-testid="ProfilePage_Partner">{partner}</span> <br />
      {services.length > 0 && (
        <>
          <strong>Services:</strong>{' '}
          <span data-testid="ProfilePage_Services">{services.join(', ')}</span>
          <hr />
        </>
      )}
      <strong>AsurionId:</strong> {asurionId}
      <br />
      <strong>Email:</strong> {email}
      <hr />
      <strong>AccessToken:</strong> {accessToken}
      <hr />
      <Link to="/">Back to mainpage</Link>
    </div>
  );
};

export default Profile;
