import React, { FC } from 'react';
import { UnorderedList, Text } from '@soluto-private/mx-asurion-ui-react-v3';
import styled from 'styled-components';

const List = styled(UnorderedList)`
  &&& {
    padding-top: 0.67rem;

    ul,
    li:last-child {
      padding: 0;
    }
  }
`;

const items: string[] = ['Age of router', 'Router replacement'];

export const ImpactList: FC = () => (
  <div>
    <Text size={3} weight="heavy">
      Things that may impact your speed:
    </Text>
    <List hasIcons isSpacingCompact>
      {items.length &&
        items.map((item, index) => {
          return (
            <UnorderedList.ListItem
              key={`support-list-item-${index}`}
              iconColor="#EDB800"
              iconSrc="SystemAlertWarning"
            >
              <React.Fragment key={`support-list-item-${index}.0`}>
                {item}
              </React.Fragment>
            </UnorderedList.ListItem>
          );
        })}
    </List>
  </div>
);
