import styled from 'styled-components';
import React from 'react';

const Rings = () => {
    const RingContainer = styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        width: 209.97px;
        height: 209.97px;
    `;
    const Circle = styled.div`
        width: ${(props: { size: number }) => props.size}px;
        height: ${(props: { size: number }) => props.size}px;
        border-radius: 50%;
        margin: 10px;
        position: absolute;
    `;

    const CircleA = styled(Circle)`
        background-color: #f0f0f5;
        opacity: 0.5;
        z-index: -3;
    `;

    const CircleB = styled(Circle)`
        background-color: #f0f0f5;
        z-index: -2;
    `;

    const CircleC = styled(Circle)`
        background-color: white;
        z-index: -1;
    `;

    return (
        <RingContainer>
            <CircleA size={210} />
            <CircleB size={134} />
            <CircleC size={97.5} />
        </RingContainer>
    );
};

export default Rings;
