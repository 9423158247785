import React, { useEffect } from 'react';
import styled from 'styled-components';
import { breakpoints } from '@soluto-private/mx-asurion-ui-react-v3';
import { SpeedTestStatus } from '@soluto-private/wixi-web-sdk';
import { Welcome } from 'components/PageHeader/Welcome';
import { formatSpeed } from 'utils/NetworkScan';
import { useNetworkScan } from 'hooks/useNetworkScan';
import { SpeedProgress } from './SpeedProgress';
import { SpeedResult } from './SpeedResult';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem;
`;

const SpeedContainer = styled.div`
  gap: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 17.25rem;

  & > * {
    margin: 0 auto;
  }
`;

const Wrapper = styled.div`
  gap: 0.75rem;
  display: flex;
  flex-direction: column;

  ${breakpoints.md2} {
    padding: 0 1.5rem;
  }
`;

export const NetworkScanInProgress = () => {
  const {
    startScan,
    downloadSpeed,
    uploadSpeed,
    downloadSpeedLabel,
    uploadSpeedLabel,
    scanState,
  } = useNetworkScan();

  const isDlCompleted = Boolean(
    downloadSpeed && scanState !== SpeedTestStatus.inProgressDownload
  );
  const isUpCompleted = Boolean(
    uploadSpeed && scanState !== SpeedTestStatus.inProgressUpload
  );

  useEffect(() => {
    setTimeout(() => {
      startScan();
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <Welcome
        pageTitle={'Testing your network now...'}
        pageSubTitle="Try not to move. The test may take up to 2 minutes."
      />
      <Container>
        {scanState === SpeedTestStatus.inProgressDownload ||
        scanState !== SpeedTestStatus.inProgressUpload ? (
          <SpeedProgress
            progressData={{
              speed: formatSpeed(downloadSpeed),
              status: 'Testing download speed',
            }}
          />
        ) : (
          <SpeedProgress
            progressData={{
              speed: formatSpeed(uploadSpeed),
              status: 'Testing upload speed',
            }}
          />
        )}
        <SpeedContainer>
          <SpeedResult
            title="Download speed"
            speedValue={downloadSpeedLabel}
            isLoading={!isDlCompleted}
            variant="download"
          />
          <SpeedResult
            title="Upload speed"
            speedValue={uploadSpeedLabel}
            isLoading={!isUpCompleted}
            variant="upload"
          />
        </SpeedContainer>
      </Container>
    </Wrapper>
  );
};
