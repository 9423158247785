import React, { FC } from 'react';
import { Container } from 'components/Main';
import {
  Button as AsurionButton,
  CheckboxSelectionCard,
  breakpoints,
} from '@soluto-private/mx-asurion-ui-react-v3';
import styled from 'styled-components';
import QuestionTextWrapper from './questionTextWrapper';
import { updateHeader } from '@soluto-private/mx-app-header';
import { slowWifiQuestion, SlowWifiValues } from '@streaming-advisor/questions';
import { usePageState } from 'hooks/usePageState';
import { RoutePath } from 'RoutePath';
import { useFlow } from 'hooks/useFlow';
import {
  withClickAnalytics,
  withSubmitAnalytics,
} from '@streaming-advisor/analytics';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;

  ${breakpoints.xs} {
    padding: 0px 16px;
    margin-bottom: 48px;
  }

  ${breakpoints.sm} {
    padding: 0px 16px;
    margin-bottom: 48px;
  }

  ${breakpoints.md} {
    padding: 0px 16px;
    margin-bottom: 48px;
  }

  ${breakpoints.md2} {
    padding: 0px 48px;
    margin-top: 46px;
  }

  ${breakpoints.lg} {
    padding: 0px 48px;
    margin-top: 46px;
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  > label {
    width: 100%;
  }
`;

const Button = withClickAnalytics(AsurionButton);
const SubmitButton = withSubmitAnalytics(AsurionButton);

const Question2: FC = () => {
  const { goTo } = useFlow();
  const {
    page: { questionState },
    setQuestionState,
  } = usePageState();

  const onBack = () => {
    goTo(RoutePath.OnlineActivitiesQ1);
  };

  updateHeader({
    view: 'service-view',
    menu: true,
    onBack,
    subNavConfig: {
      showBackButton: true,
      showPartnerLogo: true,
    },
  });

  const onSetSelected = (key: SlowWifiValues) => {
    const { q1, q2 } = questionState;

    const isNeverSlow = key === 'neverSlow';
    if (q2.includes(key)) {
      const newQ2 = q2.filter((item) => item !== key);
      setQuestionState({
        q1,
        q2: newQ2,
      });
    } else {
      const checkedItems = [...q2, key].filter((key) => key !== 'neverSlow');
      setQuestionState({
        q1,
        q2: isNeverSlow ? ['neverSlow'] : checkedItems,
      });
    }
  };

  const onNext = () => {
    goTo(RoutePath.WifiLoading);
  };

  const buttonRightGroup = (
    <>
      <Button onClick={onNext} variant="outline" color="secondary">
        Skip
      </Button>
      <SubmitButton
        onClick={onNext}
        disabled={!questionState.q2.length}
        color="secondary"
        analyticsData={{
          slowWifiOptions: questionState.q2,
        }}
      >
        Continue
      </SubmitButton>
    </>
  );

  return (
    <Container
      steps={['completed', 'inprogress']}
      footer={{
        onBack,
        buttonRightGroup,
      }}
    >
      <Wrapper>
        <QuestionTextWrapper
          question={2}
          title="Do you ever experience slow Wi-Fi?"
          subtitle="Select all that apply:"
        />
        <CheckboxWrapper>
          {slowWifiQuestion.options.map((item) => (
            <CheckboxSelectionCard
              key={item.value}
              checked={questionState.q2.includes(item.value)}
              onChange={() => onSetSelected(item.value)}
            >
              {item.label}
            </CheckboxSelectionCard>
          ))}
        </CheckboxWrapper>
      </Wrapper>
    </Container>
  );
};

export default Question2;
