import React from 'react';
import styled from 'styled-components';
import { Welcome } from 'components/PageHeader/Welcome';
import { Room } from 'components/Room/Room';
import { room } from 'pages/landing/assets';

const Wrapper = styled.div`
  gap: 3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const LandingPage = () => {
  return (
    <Wrapper data-testid="HomePage_Container">
      <Welcome
        pageTitle={"Welcome. Let's test your internet"}
        pageSubTitle="Move to the room you want to test, and select “Get started”"
      />
      <Room
        title="What room should I test?"
        subTitle={
          "We recommend the room that's furthest from your router. You could also test a room with a lot of devices or where you connect with the internet the most."
        }
        image={room}
      />
    </Wrapper>
  );
};

export default LandingPage;
