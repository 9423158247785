import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import Root from './root';

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  renderType: 'render',
  errorBoundary: (error, _info, _props) => {
    console.error(error);
    return <p>Error</p>;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
