import { WixiCore } from '@soluto-private/wixi-web-sdk';
import { environment } from 'environments/environment';

export const initializeWixiSdk = (accessToken: string): void => {
  WixiCore.initialize({
    wixiPartner: environment.wixiPartnerConfig,
    wixiAuth: {
      getAuthToken: () => Promise.resolve(accessToken),
    },
  });
};
