import { CheckboxType } from './models';

export type SlowWifiValues =
  | 'certainTimes'
  | 'certainRoom'
  | 'severalPeople'
  | 'certainDevice'
  | 'alwaysSlow'
  | 'otherTimes'
  | 'neverSlow';

const options: CheckboxType<SlowWifiValues>[] = [
  {
    value: 'certainTimes',
    label: 'Around a certain time of day',
  },
  {
    value: 'certainRoom',
    label: 'When I’m in a certain room',
  },
  {
    value: 'severalPeople',
    label: 'When several people are using it',
  },
  {
    value: 'certainDevice',
    label: 'On a certain device',
  },
  {
    value: 'alwaysSlow',
    label: 'It’s always slow',
  },
  {
    value: 'otherTimes',
    label: 'During other times',
  },
  {
    value: 'neverSlow',
    label: 'I don’t experience slow Wi-Fi',
  },
];

export const slowWifiQuestion = {
  customerQuestion: 'Do you ever experience slow Wi-Fi?',
  expertQuestion: 'When does the customer experience slow wifi?',
  options,
};
