import { SpeedTestStatus, WixiSnap } from '@soluto-private/wixi-web-sdk';
import { atom } from 'recoil';
import { sessionStorageEffect } from '../effects/sessionStorageEffect';

export interface RouterState {
  wixiSnap: WixiSnap | null;
  scanState: SpeedTestStatus;
}

export const routerState = atom<RouterState>({
  key: 'routerState',
  default: {
    wixiSnap: null,
    scanState: SpeedTestStatus.none,
  },
  effects: [sessionStorageEffect('routerState', ['scanState'])],
});
