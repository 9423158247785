import React from 'react';
import { Welcome } from 'components/PageHeader/Welcome';
import styled from 'styled-components';
import { breakpoints } from '@soluto-private/mx-asurion-ui-react-v3';
import { Room } from 'components/Room/Room';
import { techies } from 'pages/routerSpeedTestIntroductionPage/assets';

const RouterSpeedTestIntroductionPage = () => {
  const Wrapper = styled.div`
    gap: 48px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;

    ${breakpoints.md} {
      padding: 0px 16px;
      margin-bottom: 48px;
    }

    ${breakpoints.md2} {
      padding: 0px 48px;
      margin-top: 46px;
    }

    ${breakpoints.lg} {
      padding: 0px 48px;
      margin-top: 46px;
    }
  `;

  return (
    <Wrapper data-testid="RouterSpeedIntroductionPage_Container">
      <Welcome
        pageTitle={"Cool, let's test your router speed"}
        pageSubTitle="Move to the room closest to your router and select “Start test” to start."
      />

      <Room
        title="What room should I test?"
        subTitle={
          "We recommend testing in the room closest to your router. It's okay if you can't stand directly beside your router, just get as close as you can."
        }
        image={techies}
      />
    </Wrapper>
  );
};

export default RouterSpeedTestIntroductionPage;
