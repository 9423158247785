import { useEffect, useState } from 'react';
import { Size } from '@soluto-private/mx-asurion-ui-react-v3';

const defaultQuery = `(max-width: ${Size.MD2}px)`;

/**
 * return boolean based on media query size
 * @returns {boolean} true if media query is table or mobile, or false if not
 */
export const useCheckIfMobileSize = (query: string = defaultQuery): boolean => {
  const [matches, setMatches] = useState<boolean>(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    media.addEventListener('change', listener);
    return () => media.removeEventListener('change', listener);
  }, [matches, query]);

  return matches;
};
