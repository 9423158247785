import React, { FC, ReactNode } from 'react';
import {
  ButtonGroup,
  colors,
  Text,
} from '@soluto-private/mx-asurion-ui-react-v3';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: sticky;
  width: 100%;
  bottom: 0;
`;

const ButtonGroupWrapper = styled.div`
  border-top: 0.0625rem solid ${colors.neutralBrighter};
  background-color: ${colors.white};
  padding: 1rem;
`;

const BottomBar = styled.div`
  background: rgba(248, 248, 248, 0.82);
  box-shadow: 0px -0.3px 0px #aeaeb4;
  backdrop-filter: blur(29.9px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 14px;
`;

type FooterMobile = {
  buttonGroup?: ReactNode;
};

const FooterMobile: FC<FooterMobile> = ({ buttonGroup }) => (
  <Wrapper>
    <ButtonGroupWrapper>
      <ButtonGroup hasFullWidthButtons alignment="center">
        {buttonGroup}
      </ButtonGroup>
    </ButtonGroupWrapper>
    <BottomBar>
      <Text color="rgba(0, 0, 0, 0.5)" size={0}>
        my.asurion.com
      </Text>
    </BottomBar>
  </Wrapper>
);

export default FooterMobile;
