import {
  streamingIcon,
  streaming4kIcon,
  gamingIcon,
  downloadingUploadingIcon,
  callingIcon,
  noneOfTheseIcon,
} from './assets';
import { CheckboxType } from './models/CheckboxType';

export type OnlineActivityValues =
  | 'streaming'
  | '4kstreaming'
  | 'gaming'
  | 'downloadingUploading'
  | 'calling'
  | 'noneOfThese';

const options: CheckboxType<OnlineActivityValues>[] = [
  {
    value: 'streaming',
    icon: streamingIcon,
    label: 'Streaming',
    subLabel: '(Netflix, YouTube)',
  },
  {
    value: '4kstreaming',
    icon: streaming4kIcon,
    label: '4k Streaming',
    subLabel: '(Netflix, YouTube)',
  },
  {
    value: 'gaming',
    icon: gamingIcon,
    label: 'Gaming',
    subLabel: '(Xbox®, PlayStation®)',
  },
  {
    value: 'downloadingUploading',
    icon: downloadingUploadingIcon,
    label: 'Downloading or uploading large files',
  },
  {
    value: 'calling',
    icon: callingIcon,
    label: 'Video or FaceTime calls',
  },
  {
    value: 'noneOfThese',
    icon: noneOfTheseIcon,
    label: 'None of these',
  },
];

export const onlineActivitiesQuestion = {
  customerQuestion: 'Which of these activities does your household do online?',
  expertQuestion: 'Which of these activities does the customer do online?',
  options,
};
